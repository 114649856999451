<template>
  <div id="singleApply">
    <header-nav :content="`${$t('pages_router_036')} / ${ $t('pages_router_024')}`" />
    <div class="common-card-body">
      <div class="single-header" @click="$router.replace('/funds/outbounds/batch_apply')">
        <swap-outlined class="ic-switch" />
        {{ $t('pages_pay_013') }}
      </div>
      <div style="display: flex; justify-content: center">
        <a-steps :current="1" size="small" class="single-apply-steps">
          <a-step :title="$t('pages_pay_014')" />
          <a-step :title="$t('pages_pay_015')" />
          <a-step :title="$t('pages_pay_016')" />
        </a-steps>
      </div>
      <div style="display: flex; justify-content: center">
        <a-form
          ref="applyRef"
          class="ant-round-form single-apply-form"
          layout="vertical"
          :model="applyForm"
          :rules="applyRules"
          @finish="applySubmit"
          @finishFailed="submitError"
        >
          <a-form-item name="payeeId">
            <template #label>
              <span>{{ $t('pages_pay_028') }}</span>
              <div class="area-add flex-align" @click="$router.push('/funds/outbounds/payee_edit')">
                <img src="~@/assets/icons/ic-user-add.png">
              </div>
            </template>
            <div class="person-info flex-align" @click="payeeChange">
              <img v-if="payeeInfo.code" class="img-add" src="~@/assets/icons/ic-user.png">
              <span style="margin-left: 12px" class="ellipsis">{{ payeeInfo.name }}</span>
              <div v-if="payeeInfo.code" style="margin-left: auto" class="flex-align">
                <img class="img-country" :src="getCountryLogo(payeeInfo.currency)">
                <span style="margin-left: 9px">{{ payeeInfo.currency }}</span>
                <span style="margin-left: 15px" class="ellipsis">{{ payeeInfo.branchName }}·{{ payeeInfo.bankNum }}</span>
                <up-outlined v-if="payeeSelected" class="ic-select" />
                <down-outlined v-else class="ic-select" />
              </div>
              <div v-show="payeeSelected" class="select-area" @click="e => e.stopPropagation()">
                <person-select
                  ref="payeeRef"
                  method-type="outbound"
                  @select="payeeSelect"
                />
              </div>
            </div>
          </a-form-item>
          <a-form-item v-if="paymentMode === 'SWIFT' && openPobo" name="poboPayerName">
            <template #label>
              <span>{{ $t('pages_pay_283') }}</span>
              <a-tooltip :title="$t('pages_pay_285')"><question-circle-filled class="ic-tip" /></a-tooltip>
            </template>
            <div class="settle-list">
              <div
                class="settle-card"
                :class="applyForm.poboPayerName === merchantName ? 'active' : ''"
                @click="cardChange(merchantName, 'poboPayerName')"
              >
                <div class="title">{{ $t('pages_pay_279') }}</div>
                <div class="content">{{ merchantName }}</div>
              </div>
              <div
                class="settle-card"
                :class="applyForm.poboPayerName === $t('pages_pay_284') ? 'active' : ''"
                @click="cardChange($t('pages_pay_284'), 'poboPayerName')"
              >
                <div class="title">{{ $t('pages_pay_286') }}</div>
                <div class="content">{{ $t('pages_pay_284') }}</div>
              </div>
            </div>
          </a-form-item>
          <a-form-item v-if="paymentMode === 'SWIFT'" name="feeDirection" :label="$t('pages_pay_323')">
            <div class="settle-list">
              <div
                class="settle-card"
                style="padding-bottom: 24px; flex: 0 0 31%"
                :class="applyForm.feeDirection === 'SHA' ? 'active' : ''"
                @click="cardChange('SHA', 'feeDirection')"
              >
                <div class="title">{{ $t('pages_pay_324') }}</div>
                <div class="content">SHA</div>
                <a-tooltip :title="$t('pages_pay_327')">
                  <a-tag class="explain-text">{{ $t('pages_pay_327') }}</a-tag>
                </a-tooltip>
              </div>
              <div
                class="settle-card"
                style="padding-bottom: 24px; flex: 0 0 31%"
                :class="applyForm.feeDirection === 'OUR' ? 'active' : ''"
                @click="cardChange('OUR', 'feeDirection')"
              >
                <div class="title">{{ $t('pages_pay_325') }}</div>
                <div class="content">OUR</div>
                <a-tooltip :title="$t('pages_pay_328')">
                  <a-tag class="explain-text">{{ $t('pages_pay_328') }}</a-tag>
                </a-tooltip>
              </div>
              <div
                class="settle-card"
                style="padding-bottom: 24px; flex: 0 0 31%; margin-right: 0"
                :class="applyForm.feeDirection === 'BEN' ? 'active' : ''"
                @click="cardChange('BEN', 'feeDirection')"
              >
                <div class="title">{{ $t('pages_pay_326') }}</div>
                <div class="content">BEN</div>
                <a-tooltip :title="$t('pages_pay_329')">
                  <a-tag class="explain-text">{{ $t('pages_pay_329') }}</a-tag>
                </a-tooltip>
              </div>
            </div>
          </a-form-item>
          <a-row>
            <a-col :span="11">
              <a-form-item name="payAmount">
                <template #label>
                  <div class="flex-align" style="width: 100%">
                    <span>{{ $t('pages_pay_032') }}</span>
                    <span v-if="maxPayAmount !== null" class="amount-text">
                      {{ $t('pages_pay_034') }} {{ parseMoney(maxPayAmount) }}
                    </span>
                  </div>
                </template>
                <number-input
                  v-model:input-num="applyForm.payAmount"
                  :min="0"
                  input-size="large"
                  :input-style="{paddingRight: '50%'}"
                  @update="payAmountChange"
                />
                <span
                  v-if="maxPayAmount !== null"
                  class="amount-text full-all"
                  @click="fullAllMount"
                >{{ $t('pages_pay_035') }}</span>
                <a-form-item-rest>
                  <a-select
                    v-model:value="curInfo.payCur"
                    size="large"
                    show-search
                    option-filter-prop="label"
                    class="area-select"
                    :bordered="false"
                  >
                    <template #suffixIcon>
                      <img src="~@/assets/icons/ic-choose.png" class="ic-select">
                    </template>
                    <a-select-option
                      v-for="item in payCurList"
                      :key="item.currency"
                      :value="item.currency"
                      :label="`${item.currency} - ${item.currencyName}`"
                    >
                      <img :src="getCountryLogo(item.currency)" class="select-img-country">
                      <span>{{ item.currency }}</span>
                    </a-select-option>
                  </a-select>
                </a-form-item-rest>
              </a-form-item>
            </a-col>
            <a-col :span="2" class="flex-align" style="justify-content: center">
              <img class="ic-single" src="~@/assets/icons/ic-single.png">
            </a-col>
            <a-col :span="11">
              <a-form-item name="receiveAmount" :label="$t('pages_pay_081')">
                <number-input
                  v-model:input-num="applyForm.receiveAmount"
                  :min="0"
                  input-size="large"
                  @update="receiveAmountChange"
                />
                <a-form-item-rest>
                  <a-select
                    v-model:value="curInfo.receiveCur"
                    size="large"
                    show-search
                    option-filter-prop="label"
                    class="area-select"
                    :disabled="applyForm.payeeId"
                    :bordered="false"
                  >
                    <template #suffixIcon>
                      <img src="~@/assets/icons/ic-choose.png" class="ic-select">
                    </template>
                    <a-select-option
                      v-for="item in receiveCurList"
                      :key="item.currencyCode"
                      :value="item.currencyCode"
                      :label="`${item.currencyCode} - ${item.currencyName}`"
                    >
                      <img :src="getCountryLogo(item.imgName || item.currencyCode)" class="select-img-country">
                      <span>{{ item.currencyCode }}</span>
                    </a-select-option>
                  </a-select>
                </a-form-item-rest>
              </a-form-item>
              <div v-if="applyForm.feeDirection === 'SHA'" class="mode-explain">{{ $t('pages_pay_340') }}</div>
              <div v-if="applyForm.feeDirection === 'BEN'" class="mode-explain">{{ $t('pages_pay_341') }}</div>
            </a-col>
          </a-row>
          <div v-show="curInfo.payCur !== curInfo.receiveCur" style="margin-bottom: 24px">
            <div class="exchange-rate flex-align">
              <a-spin v-if="loading" :tip="$t('pages_exchange_030')" />
              <div v-else-if="rateInfo.rate" class="rate-info flex-align">
                <img src="~@/assets/icons/ic-great.png">
                <div class="rate flex-align">
                  <a-tooltip :title="$t('pages_pay_046')">
                    <span>{{ $t('pages_exchange_028') }}</span>
                    <span style="margin-left: 5px">1 {{ rateInfo.arithmetic === 'DIV' ? curInfo.receiveCur : curInfo.payCur }} = {{ rateInfo.rate }} {{ rateInfo.arithmetic === 'DIV' ? curInfo.payCur : curInfo.receiveCur }}</span>
                  </a-tooltip>
                </div>
                <div class="update-date">
                  {{ $t('pages_exchange_029') }} {{ rateInfo.queryDate }}
                </div>
              </div>
              <div v-else class="rate-error">{{ rateInfo.resMsg }}</div>
              <div v-if="!loading" class="refresh-process">
                <count-down
                  :valid-time="rateInfo.validTime"
                  :trade-currency="`${curInfo.payCur}${curInfo.receiveCur}`"
                  @refresh="loadExchangeRate"
                />
              </div>
            </div>
            <div v-if="rateInfo.rate" class="explain">{{ $t('pages_pay_046') }}</div>
          </div>

          <a-spin v-show="feeLoading" :tip="$t('pages_pay_050')" style="margin-top: 18px" />
          <div v-show="feeInfo.feeAmt !== null" class="fee-detail flex-align">
            <img src="~@/assets/icons/ic-money.png">
            <span>{{ $t('pages_pay_047') }}</span>
            <span style="margin-left: 5px">{{ parseMoney(feeInfo.feeAmt) }} {{ feeInfo.feeCurrency }}</span>
            <a-tag style="margin-left: 8px" color="pink">{{ feeInfo.feePattern === '00' ? $t('pages_pay_048') : $t('pages_pay_049') }}</a-tag>
          </div>
          <a-form-item name="purpose" :label="$t('pages_pay_330')">
            <a-select
              v-model:value="applyForm.purpose"
              allow-clear
              show-search
              option-label-prop="label"
              option-filter-prop="label"
              :placeholder="$t('common_text_005') + $t('pages_pay_330')"
            >
              <a-select-option
                v-for="item in purposeList"
                :key="item.code"
                :value="item.code"
                :label="item.name"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="merSingleNo" :label="$t('pages_pay_056')">
            <a-input v-model:value="applyForm.merSingleNo" :placeholder="$t('pages_pay_058')" allow-clear />
          </a-form-item>
          <a-form-item name="tradeContent" :label="$t('pages_pay_057')">
            <a-textarea v-model:value="applyForm.tradeContent" class="input-primary" :placeholder="$t('common_text_006')" allow-clear />
          </a-form-item>
          <a-descriptions
            :label-style="{fontSize: '12px', padding: '12px 24px', background: 'transparent'}"
            :content-style="{fontSize: '12px', padding: '12px 24px'}"
            :column="1"
            bordered
          >
            <template #title>
              <span style="font-size: 14px; font-weight: 500">{{ $t('pages_pay_335') }}</span>
            </template>
            <a-descriptions-item :label="$t('pages_pay_078')">{{ getTradeStatusName(paymentMode, 'paymentMode') }}</a-descriptions-item>
            <a-descriptions-item :label="$t('pages_pay_042').split(':')[0]">
              <div v-if="feeStandard">
                {{ feeStandard }}
                <span v-if="feeInfo.minFee !== null" style="margin-left: 8px; color: #ff4d4f">
                  {{ $t('pages_pay_337') }}{{ feeInfo.minFee }} {{ feeInfo.feeCurrencyUnit }}
                </span>
              </div>
              <div
                v-else-if="!feeStandard && isRequest"
                style="color: #ff4d4f"
              >{{ `${$t('pages_pay_059')}, ${$t('pages_pay_372')}` }}</div>
            </a-descriptions-item>
            <a-descriptions-item :label="$t('pages_pay_036')">
              <div>{{ paymentMode === 'SWIFT' ? $t('pages_pay_311') : $t('pages_pay_315') }}</div>
              <div>{{ $t('pages_pay_312') }}</div>
            </a-descriptions-item>
          </a-descriptions>
          <a-form-item v-if="getUsedCheck(pageLabs, 170299)" style="text-align: right">
            <a-button
              :loading="submitLoading"
              html-type="submit"
              type="primary"
              shape="round"
              style="min-width: 138px; margin-top: 24px"
            >{{ $t('common_text_002') }}</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderNav from '@/components/header-nav'
import PersonSelect from '@/components/person-select'
import CountDown from '@/components/count-down'
import NumberInput from '@/components/number-input'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { h, ref, reactive, toRefs, computed, watch, onUnmounted, createVNode } from 'vue'
import { message, Modal, Input } from 'ant-design-vue'
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import i18n from '@/locale'
import { _pay, _receive, _account, _user } from '@/api'
import { getCountryLogo, parseMoney, getTradeStatusName, getAttrStatusList, evalMathStr, getUsedCheck } from '@/util'
export default {
  name: 'SingleApply',
  components: {
    'header-nav': HeaderNav,
    'person-select': PersonSelect,
    'count-down': CountDown,
    'number-input': NumberInput
  },
  setup () {
    let timer = null
    let timerSelect = null
    const payeeRef = ref(null)
    const applyRef = ref(null)
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    const validatePerson = (rule) => {
      const { payeeId, draweeId } = state.applyForm
      if (!payeeId && rule.field === 'payeeId') return Promise.reject(i18n.global.t('pages_pay_017'))
      if (!draweeId && rule.field === 'draweeId') return Promise.reject(i18n.global.t('pages_pay_018'))
      return Promise.resolve()
    }
    const validatorAmount = (rule, value) => {
      if (!value) return Promise.reject(i18n.global.t('pages_pay_019'))
      if (!(!isNaN(parseFloat(value)) && isFinite(value))) return Promise.reject(i18n.global.t('pages_pay_020'))
      if (String(value).indexOf('.') !== -1) {
        if (state.curInfo.payCur === 'JPY' && rule.field === 'payAmount') {
          return Promise.reject(i18n.global.t('pages_pay_367', ['JPY', '0']))
        }
        if (String(value).split('.')[1].length > state.precision && rule.field === 'receiveAmount') {
          return Promise.reject(i18n.global.t('pages_pay_367', [state.payeeInfo.currency, state.precision]))
        }
      }
      if (value > state.maxPayAmount && rule.field === 'payAmount' && state.maxPayAmount !== null) {
        return Promise.reject(i18n.global.t('pages_pay_023'))
      }
      if (value > state.channelMax && rule.field === 'receiveAmount' && state.channelMax !== null) {
        return Promise.reject(i18n.global.t('pages_pay_338'))
      }
      if (value < state.channelMin && rule.field === 'receiveAmount' && state.channelMin !== null) {
        return Promise.reject(i18n.global.t('pages_pay_339'))
      }
      if (String(value).split('.')[0].length > 16) return Promise.reject(i18n.global.t('pages_pay_368'))
      return Promise.resolve()
    }

    const state = reactive({
      loading: true,
      feeLoading: false,
      submitLoading: false,
      isRequest: false,
      maxPayAmount: null,
      channelMin: null,
      channelMax: null,
      payeeSelected: false,
      payFinally: false,
      receiveFinally: false,
      merchantName: '',
      openPobo: false,
      precision: 2,
      payeeInfo: {},
      payees: [],
      pageLabs: [],
      payCurList: [],
      receiveCurList: [],
      paymentMode: '',
      rateInfo: {
        rate: '',
        arithmetic: '',
        queryDate: '',
        resMsg: '',
        validTime: 12
      },
      feeInfo: {
        feeAmt: null,
        feePattern: '00',
        feeCurrency: '',
        feeCurrencyUnit: '',
        feeAmtUnit: null,
        feeRateUnit: null,
        minFee: null
      },
      curInfo: {
        payCur: 'USD',
        receiveCur: ''
      },
      applyForm: {
        payeeId: route.query.payeeId || '',
        poboPayerName: '',
        feeDirection: '',
        payAmount: null,
        receiveAmount: null,
        merSingleNo: '',
        tradeContent: '',
        purpose: null
      },
      applyRules: {
        payeeId: [{ required: true, validator: validatePerson, trigger: 'blur' }],
        poboPayerName: [{ required: true }],
        feeDirection: [{ required: true }],
        payAmount: [{ required: true, validator: validatorAmount }],
        receiveAmount: [{ required: true, validator: validatorAmount }],
        purpose: [{ required: true }],
        merSingleNo: [{ max: 32, message: i18n.global.t('common_text_053') }],
        tradeContent: [{ max: 256, message: i18n.global.t('common_text_026') }]
      }
    })

    state.purposeList = computed(() => {
      return getAttrStatusList('singlePurpose')
    })

    state.feeStandard = computed(() => {
      if (state.feeInfo.feeAmtUnit !== null && state.feeInfo.feeRateUnit !== null) {
        return `${state.feeInfo.feeAmtUnit}${state.feeInfo.feeCurrencyUnit}/${i18n.global.t('pages_pay_295')} + ${state.feeInfo.feeRateUnit}%/${i18n.global.t('pages_pay_295')}`
      } else if (state.feeInfo.feeAmtUnit !== null) {
        return `${state.feeInfo.feeAmtUnit}${state.feeInfo.feeCurrencyUnit}/${i18n.global.t('pages_pay_295')}`
      } else if (state.feeInfo.feeRateUnit !== null) {
        return `${state.feeInfo.feeRateUnit}%/${i18n.global.t('pages_pay_295')}`
      } else {
        return ''
      }
    })

    onUnmounted(() => {
      clearInterval(timer)
      clearInterval(timerSelect)
    })

    watch(() => state.curInfo.payCur,
      async () => {
        state.applyForm.payAmount = null
        state.applyForm.receiveAmount = null
        state.applyForm.merSingleNo = ''
        state.applyForm.tradeContent = ''
        state.applyForm.purpose = null
        await loadExchangeRate()
        await getMaxPayAmount(true)
        getFeeAmount()
      }
    )

    const fullAllMount = () => {
      state.applyForm.payAmount = state.maxPayAmount
      payAmountChange()
      applyRef.value.validateFields('payAmount')
    }

    const cardChange = async (value, attr) => {
      state.applyForm[attr] = value
      applyRef.value.validateFields(attr)
      await getMaxPayAmount()
      getFeeAmount()
    }

    const payAmountChange = () => {
      state.payFinally = true
      state.receiveFinally = false
      computeReceiveAmount()
      clearInterval(timer)
      timer = setInterval(() => {
        clearInterval(timer)
        getFeeAmount()
      }, 300)
    }

    const receiveAmountChange = () => {
      state.receiveFinally = true
      state.payFinally = false
      computePayAmount()
      clearInterval(timer)
      timer = setInterval(() => {
        clearInterval(timer)
        getFeeAmount()
      }, 300)
    }

    const payeeChange = () => {
      state.payeeSelected = !state.payeeSelected
    }

    const payeeSelect = async (selectId) => {
      clearInterval(timerSelect)
      timerSelect = setInterval(() => {
        clearInterval(timerSelect)
        chooseContinue(selectId)
      }, 500)
    }

    const chooseContinue = async (selectId) => {
      const res = await _pay.outboundPayeeCheck({ payeeId: selectId })
      if (res.data.succ) {
        if (res.data.data) {
          payeeRef.value.initData(state.applyForm.payeeId, state.payees)
          Modal.confirm({
            title: i18n.global.t('common_text_037'),
            icon: createVNode(InfoCircleOutlined),
            content: createVNode('div', {}, [
              createVNode('div', {}, i18n.global.t('pages_pay_331')),
              createVNode('div', { style: { marginTop: '5px' }}, i18n.global.t('pages_pay_332')),
              createVNode('div', { style: { marginTop: '5px' }}, i18n.global.t('pages_pay_333'))
            ]),
            width: 468,
            okText: i18n.global.t('pages_pay_334'),
            onOk() {
              router.push('/funds/payee_manage')
            }
          })
        } else {
          state.applyForm.payeeId = selectId
          state.applyForm.poboPayerName = ''
          state.applyForm.feeDirection = ''
          state.applyForm.payAmount = null
          state.applyForm.receiveAmount = null
          state.applyForm.merSingleNo = ''
          state.applyForm.tradeContent = ''
          state.applyForm.purpose = null
          state.payeeInfo = state.payees.find(item => item.code === selectId)
          state.curInfo.receiveCur = state.payeeInfo.currency
          state.paymentMode = state.payeeInfo.paymentMode
          applyRef.value.validateFields('payeeId')
          await loadExchangeRate()
          await getMaxPayAmount(true)
          getFeeAmount()
          payeeChange()
          loadPrecision()
        }
      } else {
        message.error(res.data.resMsg)
        payeeRef.value.initData(state.applyForm.payeeId, state.payees)
      }
    }

    const submitError = () => {
      message.error(i18n.global.t('common_text_030'))
    }

    const applySubmit = async () => {
      if (state.feeInfo.feeAmt === null) return message.error(i18n.global.t('pages_pay_059'))
      if (!state.rateInfo.rate) return message.error(i18n.global.t('pages_pay_060'))
      state.submitLoading = true
      const { payCur, receiveCur } = state.curInfo
      const { payAmount, receiveAmount } = state.applyForm
      const data = Object.assign({}, state.applyForm, {
        debitAmt: payAmount,
        debitCurrency: payCur,
        isOpenPobo: state.applyForm.poboPayerName === state.merchantName,
        paymentAmt: receiveAmount,
        paymentCurrency: receiveCur,
        amountMark: state.payFinally ? 'remit' : 'arrived',
        applyNo: state.feeInfo.applyNo
      })
      _pay.paymentSingleSubmit(data)
        .then(res => {
          state.submitLoading = false
          const { resCode } = res.data
          if (resCode === '000000') {
            router.push({ path: '/funds/apply_success', query: { key: 'outbound' }})
          } else if (resCode === '020099') {
            let payerCity = ''
            Modal.confirm({
              title: i18n.global.t('pages_pay_290'),
              content: () => {
                return h(Input, {
                  style: { marginTop: '12px' },
                  placeholder: i18n.global.t('pages_pay_291'),
                  onChange: e => { payerCity = e.target.value }
                })
              },
              okText: i18n.global.t('common_text_002'),
              onOk: async () => {
                if (!payerCity) {
                  message.error(i18n.global.t('common_text_030'))
                } else {
                  const res = await _user.updateKycInfo({ payerCity })
                  if (res.data.succ) message.success(res.data.resMsg)
                  else message.error(res.data.resMsg)
                }
              }
            })
          } else {
            message.error(res.data.resMsg)
          }
        }).catch(err => console.log(err))
    }

    const getMaxPayAmount = async (noValid) => {
      state.isRequest = false
      state.maxPayAmount = null
      state.feeInfo.feeAmtUnit = null
      state.feeInfo.feeRateUnit = null
      state.channelMin = null
      state.channelMax = null
      let flsh = false
      const { payCur, receiveCur } = state.curInfo
      const { payeeId, poboPayerName, feeDirection } = state.applyForm
      const params = {
        debitCurrency: payCur,
        paymentCurrency: receiveCur,
        payeeId
      }
      if (state.paymentMode === 'SWIFT') {
        flsh = feeDirection && (state.openPobo ? poboPayerName : true) && payeeId
        params.feeDirection = feeDirection
        params.isOpenPobo = poboPayerName === state.merchantName
      } else {
        flsh = true
        params.isOpenPobo = false
      }
      if (flsh) {
        state.isRequest = true
        const res = await _pay.paymentSingleTrial(params)
        if (res.data.succ) {
          state.feeInfo = res.data.data
          state.maxPayAmount = res.data.data.maxAmount
          state.channelMin = res.data.data.channelMin
          state.channelMax = res.data.data.channelMax
        } else {
          message.destroy()
        }
        if (!noValid) applyRef.value.validateFields('payAmount')
      }
    }

    const getFeeAmount = async () => {
      state.feeInfo.feeAmt = null
      state.feeInfo.feePattern = ''
      state.feeInfo.feeCurrency = ''
      let flsh = false
      const { payCur, receiveCur } = state.curInfo
      const { payAmount, receiveAmount, payeeId, poboPayerName, feeDirection } = state.applyForm
      const params = {
        payeeId,
        debitCurrency: payCur,
        debitAmt: payAmount,
        paymentCurrency: receiveCur,
        paymentAmt: receiveAmount,
        amountMark: state.payFinally ? 'remit' : 'arrived'
      }
      if (state.paymentMode === 'SWIFT') {
        flsh = feeDirection && (state.openPobo ? poboPayerName : true) && payeeId && payAmount !== null && receiveAmount !== null
        params.feeDirection = feeDirection
        params.isOpenPobo = poboPayerName === state.merchantName
      } else {
        flsh = payAmount !== null && receiveAmount !== null
        params.isOpenPobo = false
      }
      if (flsh) {
        if ((!(!isNaN(parseFloat(payAmount)) && isFinite(payAmount)))) return
        state.feeLoading = true
        const res = await _pay.paymentSingleTrial(params)
        if (res.data.succ) {
          state.feeInfo = res.data.data
        } else {
          message.destroy()
        }
        state.feeLoading = false
      }
    }

    const computePayAmount = () => {
      const { receiveAmount } = state.applyForm
      const { payCur } = state.curInfo
      if (!state.rateInfo.arithmetic) return
      if (!receiveAmount) {
        state.applyForm.payAmount = null
        return
      }
      if ((!(!isNaN(parseFloat(receiveAmount)) && isFinite(receiveAmount)))) return
      let payAmount = 0
      if (state.rateInfo.arithmetic === 'DIV') {
        if (payCur === 'JPY') {
          payAmount = Math.round((evalMathStr(receiveAmount + '*' + state.rateInfo.rate) * 1))
        } else {
          payAmount = Math.round((evalMathStr(receiveAmount + '*' + state.rateInfo.rate) * 1) * 100) / 100
        }
      } else {
        if (payCur === 'JPY') {
          payAmount = Math.round((evalMathStr(receiveAmount + '/' + state.rateInfo.rate) * 1))
        } else {
          payAmount = Math.round((evalMathStr(receiveAmount + '/' + state.rateInfo.rate) * 1) * 100) / 100
        }
      }
      state.applyForm.payAmount = payAmount
      applyRef.value.validateFields('payAmount')
    }

    const computeReceiveAmount = () => {
      const { payAmount } = state.applyForm
      const { receiveCur } = state.curInfo
      if (!state.rateInfo.arithmetic) return
      if (!payAmount) {
        state.applyForm.receiveAmount = null
        return
      }
      if ((!(!isNaN(parseFloat(payAmount)) && isFinite(payAmount)))) return
      let receiveAmount = 0
      if (state.rateInfo.arithmetic === 'DIV') {
        if (receiveCur === 'JPY') {
          receiveAmount = Math.round((evalMathStr(payAmount + '/' + state.rateInfo.rate) * 1))
        } else {
          receiveAmount = Math.round((evalMathStr(payAmount + '/' + state.rateInfo.rate) * 1) * 100) / 100
        }
      } else {
        if (receiveCur === 'JPY') {
          receiveAmount = Math.round((evalMathStr(payAmount + '*' + state.rateInfo.rate) * 1))
        } else {
          receiveAmount = Math.round((evalMathStr(payAmount + '*' + state.rateInfo.rate) * 1) * 100) / 100
        }
      }
      state.applyForm.receiveAmount = receiveAmount.toFixed(state.precision)
      applyRef.value.validateFields('receiveAmount')
    }

    const loadPayeeList = async () => {
      const { payeeId } = state.applyForm
      const res = await _pay.outboundPayeeNoPageList({ status: '01' })
      if (res.data.succ) {
        state.payees = res.data.data.map(item => {
          return Object.assign({}, item, {
            code: String(item.payeeId),
            name: item.accountName,
            currency: item.accountCurrency,
            branchName: item.bankName || '-',
            bankNum: item.accountNo
          })
        })
        if (payeeId) {
          state.payeeInfo = state.payees.find(item => item.code === payeeId) || {}
          state.curInfo.receiveCur = state.payeeInfo.currency
          state.paymentMode = state.payeeInfo.paymentMode
        }
        payeeRef.value.initData(payeeId, state.payees)
        getMaxPayAmount(true)
        loadExchangeRate()
        loadPrecision()
      }
    }

    const loadPrecision = async () => {
      if (state.curInfo.receiveCur === 'JPY') {
        state.precision = 0
      } else if (state.paymentMode === 'SWIFT') {
        state.precision = 2
      } else {
        const res = await _pay.dynamicPrecision({
          countryCode: state.payeeInfo.accountCountry,
          paymentCurrency: state.payeeInfo.accountCurrency,
          paymentMode: state.payeeInfo.paymentMode
        })
        if (res.data.succ) state.precision = res.data.data.precision
      }
    }

    const loadPayCurList = async () => {
      const res = await _pay.getCurAccount()
      if (res.data.succ) {
        state.payCurList = res.data.data
      }
    }

    const loadReceiveCurList = async () => {
      const res = await _receive.getAllCurrency()
      if (res.data.succ) {
        state.receiveCurList = res.data.data
      }
    }

    const loadMerchantInfo = async () => {
      const res = await _pay.findByMerchantKycEnrol()
      if (res.data.succ) {
        const { data } = res.data
        state.openPobo = data.products ? data.products.indexOf('POBO') !== -1 : false
      }
    }

    const loadChildrenMenu = async () => {
      const res = await _account.getChildrenMenu({ parentId: 1702 })
      if (res.data.succ) {
        state.pageLabs = res.data.data || []
      }
    }

    const loadExchangeRate = async () => {
      state.loading = true
      state.rateInfo = {
        rate: '',
        arithmetic: '',
        queryDate: '',
        resMsg: '',
        validTime: 12
      }
      if (state.curInfo.payCur === state.curInfo.receiveCur) {
        state.rateInfo.rate = 1
        state.rateInfo.arithmetic = 'DIV'
        return
      }
      const res = await _pay.queryTradeRate({ payeeId: state.applyForm.payeeId, paymentCurrency: state.curInfo.receiveCur, debitCurrency: state.curInfo.payCur })
      if (res.data.succ) {
        const { data } = res.data
        if (data.debitCurrency !== state.curInfo.payCur) return
        state.rateInfo = Object.assign({}, data, {
          validTime: data.validTime ? data.validTime * (data.validUnit === 'HH' ? 3600 : data.validUnit === 'MM' ? 60 : 1) : 12
        })
      } else {
        state.rateInfo.resMsg = res.data.resMsg
        state.rateInfo.validTime = 12
        state.applyForm.payAmount = null
        state.applyForm.receiveAmount = null
      }
      state.loading = false
    }

    const init = () => {
      const userInfo = store.getters.getUserInfo
      if (userInfo) state.merchantName = userInfo.merchantName
      loadPayeeList()
      loadPayCurList()
      loadReceiveCurList()
      loadMerchantInfo()
      loadChildrenMenu()
    }

    init()

    return {
      applyRef,
      payeeRef,
      getCountryLogo,
      parseMoney,
      getUsedCheck,
      getTradeStatusName,
      payeeChange,
      payeeSelect,
      payAmountChange,
      receiveAmountChange,
      fullAllMount,
      cardChange,
      loadExchangeRate,
      applySubmit,
      submitError,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#singleApply {
  .common-card-body {
    text-align: left;
    padding: 24px 48px;
    .single-header {
      display: inline-block;
      cursor: pointer;
      .ic-switch {
        color: @main-color;
      }
    }
    .single-apply-steps {
      margin-top: 24px;
      width: 688px;
    }
    .single-apply-form {
      margin-top: 36px;
      width: 688px;
      .ellipsis {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .area-add {
        justify-content: center;
        background: @table-th-color;
        border-radius: 6px;
        width: 30px;
        height: 30px;
        margin-left: 18px;
        cursor: pointer;
        img {
          width: 15px;
          height: 15px;
        }
      }
      .person-info {
        height: 32px;
        padding: 0 11px;
        border-radius: 28px;
        border: 1px solid #d9d9d9;
        cursor: pointer;
        position: relative;
        .img-add {
          width: 20px;
          height: 20px;
        }
        .img-country {
          width: 25px;
          height: 18px;
        }
        .ic-select {
          margin-left: 48px;
          color: #999;
        }
        .select-area {
          position: absolute;
          bottom: -260px;
          left: 0;
          right: 0;
          z-index: 999;
        }
      }
      .amount-text {
        font-size: 12px;
        color: @main-color;
        font-weight: 500;
        margin: 0 11px 0 auto;
        .ic-tip {
          margin-right: 3px;
        }
      }
      .area-select {
        left: auto;
        right: 1px;
        width: 38%;
        top: 1px;
        bottom: 1px;
        background: #fff;
        .ic-select {
          width: 12px;
          margin-top: -2px;
        }
      }
      .settle-list {
        .settle-card {
          width: 180px;
        }
        .content {
          text-align: center;
        }
        .explain-text {
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
      .mode-explain {
        font-size: 12px;
        margin-top: -20px;
        opacity: 0.8;
      }
      .ant-form-item-has-error {
        .area-select {
          left: auto !important;
        }
        .mode-explain {
          margin-top: 4px;
        }
      }
      .ant-form-item-has-error + .mode-explain {
        display: none;
      }
      .ic-tip {
        margin-left: 5px;
      }
      .ic-single {
        width: 36px;
      }
      .full-all {
        position: absolute;
        top: 0;
        right: 38%;
        line-height: 38px;
        cursor: pointer;
      }
      .exchange-rate {
        .rate-info {
          img {
            width: 25px;
            height: 25px;
            margin-top: -6px;
          }
          .rate {
            font-weight: 500;
            margin-left: 8px;
          }
          .update-date {
            font-size: 12px;
            color: #666;
            margin-left: 12px;
          }
        }
        .rate-error {
          color: @error-color;
        }
        .refresh-process {
          width: 20px;
          height: 20px;
          padding: 2px;
          overflow: hidden;
          margin-left: 5px;
        }
      }
      .explain {
        padding-top: 8px;
        margin-top: 8px;
        font-size: 12px;
        border-top: 1px solid #dfdfdf;
      }
      .fee-detail {
        margin-bottom: 24px;
        img {
          width: 25px;
          height: 25px;
          margin: -2px 8px 0 0;
        }
        font-weight: 500;
      }
      .input-primary:deep(.ant-input) {
        border-radius: 5px;
      }
      .person-info:hover {
        border: 1px solid @main-color;
      }
    }
    .single-apply-form:deep(.ant-form-item-label > label) {
      width: 100%;
    }
  }
}
</style>

